.Header {
  height: 90px;
  //   background: red;
    background-color: #13131355;
    backdrop-filter: blur(20px);
  padding: 30px;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  z-index: 33; //ask
  top: 0px;
  //   display: flex;
  &_icon {
    height: 36px;
  }
  &_links {
    &_active {
      border-radius: 25px;
      background-color: #e7416a;
      padding: 10px 15px !important;
      height: 25px;
      &:hover{
        color: #e6e6e6 !important;
      }

      //   box-sizing: border-box;

      //   height: 30px;
    }
    a {
      text-decoration: none !important;
    }
    &_tab {
      padding: 10px;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.11;
      letter-spacing: normal;
      text-align: left;
      color: #e6e6e6;
      margin: 0 20px;
      &:hover{
        color: #e7416a;
      }
    }
  }
}
.Header_auth {
  width: 10vw; // pre-launch-deal 
  button {
    margin: 0 5px;

  }
  &_login {
    padding: 12px 44px 13px;
    box-sizing: border-box;
    border-radius: 100px;
    border: solid 1px #e7416a;
    background-color: transparent;
    font-family: Poppins;
    height: 50px;
    font-size: 18px;

    color: #e7416a;
    cursor: pointer;
    text-decoration: none;
  }

  &_signup {
    padding: 12px 36px 13px 35px;
    border-radius: 25px;
    background-image: linear-gradient(70deg, #e72870, #e76161);
    height: 26px;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.11;
    letter-spacing: normal;
    text-align: left;
    color: #e5e5e5;
    cursor: pointer;
    text-decoration: none;
    margin: 0 0 0 20px;

  }
}
@function vv($px-vw, $base-vw: 676px) {
  @return ($px-vw * 100vw) / $base-vw;
}
.MHeader {
  padding: vv(40px);
  // position: relative;
  &_icon {
    height: vv(30px) !important;
  }
  &_open {
    width: vv(36px);
    height: vv(24px);
    // background: wheat;
  }
  &_panel {
    width: vv(463px);
    // left: 50%;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 22;
    position: fixed;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.6);
    background-color: #242424;
    color: #e6e6e6;
    &_close {
      justify-content: flex-end;
      padding: vv(40px);
      width: 100%;
      box-sizing: border-box;
      text-align: end;
      font-size: 20px;
    }
    a {
      color: #e6e6e6;
      text-decoration: unset;
      &:hover {
        text-decoration: underline;
      }
    }
    &_link {
      align-items: flex-start;
      padding: vv(40px);
      font-family: Poppins;
      font-size: vv(31px);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.45;
      letter-spacing: 0.62px;
      text-align: left;
      color: #e6e6e6 !important;
      &_one {
        margin: vv(26px);
      }
    }
    .Header_auth {

      margin-bottom: vv(60px);
      button {
        margin: vv(15px);
      }


      &_login {
      margin: auto;
      width: 80%;
      padding: 12px 44px 13px;
      box-sizing: border-box;
      border-radius: 100px;
      font-family: Poppins;
      height: 50px;
      font-size: 18px;
      cursor: pointer;
      text-decoration: none;
      }
    
      &_signup {
      margin: 20px auto 0 auto ;
      width: 80%;
      padding: 12px 44px 13px;
      box-sizing: border-box;
      border-radius: 100px;
      font-family: Poppins;
      height: 50px;
      font-size: 18px;
      cursor: pointer;
      text-decoration: none;
      }

    }
  }
}

.top90{
  position: absolute;
  width: 100%;
  top: 90px;
}
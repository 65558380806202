@function vv($px-vw, $base-vw: 676px) {
    @return ($px-vw * 100vw) / $base-vw;
  }

.MTemplates{
    padding: 0 vv(40px);
    center {
      font-size: vv(18px);
      margin-bottom: vv(15px);
    }
    .DToggle {
        
        width: 100%;
        height: vv(66px);
        font-size: vv(20px) !important;
      
        // margin: 0 2.5px 78px 0;
        // padding: 5px 5px 5px 21px;
        padding: vv(5px);
    
        // border-radius: vv(25px);

        border-radius: vv(33px);
        background-color: #333;
      
        &_button {
            font-size: vv(18px) !important;

        //   font-size: vv(16px);
         
        }
      }
      .DToggle_active {
        width: 100%;
        height: vv(56px);
        // margin: 0 0 0 vv(16px);
        padding: 0 vv(28px);
        border-radius: vv(28px);
        
        background-color: #e7416a;
      }
      .ListTab {
        // height: 75vh;
        .infinite-scroll-component{display: flex;
            padding-top: vv(40px);
            .Card{
                margin-bottom: vv(10px);
                &_name{
                    padding:5px;
                }
            }
        }}
}
.MTemplates.popup-template{
  .Popup_header_close_close {
    cursor: pointer;
    margin-left:  vv(24px);
    width: vv(17px);
}

  .ModelCardVideo_IMG{
    height: auto;
    max-width:  vv(590px);
    align-items: center;
    justify-content: center;

    video {
      height: 100%;
      max-width: vv(550px) ;
  }




  }

  .Popup_header {
    justify-content: space-between;
    margin: vv(15px);
}

  .Popup_footer {
    margin: vv(15px);
    justify-content: center;
    width: 100%;
}

}

.DTemplates{
  .TabHeader{
    background: unset;
  }

  center{
    font-size: vv(10px);
  }
}



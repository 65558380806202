.TablePopup {
  width: 82vw;
  //   width: max-content;
  display: flex;
  flex-flow: column;
  overflow: scroll;
  height: 60vh;

  &_column {
    display: flex;
    flex-flow: row;
    &_index {
      width: 100px !important;
      background: #242424;
      border: 1px solid #4d4d4d;
    }
    &_each {
      //   width: 100%;
      padding: 10px 20px;
      background: #242424;
      font-size: 16px;
      color: #e7416a;
      font-weight: 600;
      width: 80%;
      //   max-width: 400px;
      min-width: 250px;
      //   border: 1px solid transparent;
      border: 1px solid #4d4d4d;
    }
  }
  .TableRow {
    display: flex;
    flex-flow: row;
    #table0 {
      background: #242424;
    }
    &_index {
      display: flex;
      justify-content: center;
      align-items: center;
      //   width: 30px;
      font-size: 16px;
      //   font-weight: 600;
      width: 100px !important;
      background: #242424;
      border: 1px solid #4d4d4d;
    }
    &_each {
      padding: 10px 20px;
      font-size: 16px;
      width: 80%;
      min-width: 250px;
      border: 1px solid #4d4d4d;
    }
  }
}
.AvatarChoosePopup {
  width: 350px;
  justify-content: space-between;
  &_active {
    border: 3px solid #e7416a;
  }
  &_avatar {
    width: 150px;
    border-radius: 5px;
    // background: red;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    height: 200px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    // align-items: center;
    &_img {
      // background: blue;
      height: 100%;
    }
    &_text {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
    }
  }
}

.MapPopup {
  // width: 60vw;
  width: 500px;

  &_head {
  }
  &_body {
    &_row {
      margin: 5px 0;
      align-items: center;
      &_merge {
        width: 40%;
        font-size: 16px;
      }
      .EditSelect_input {
        input {
          font-size: 16px;
          padding: 5px 10px;
          border: none;
        }
        border: none;
      }
      .SelectOption {
        width: 60%;
        font-size: 16px;

        background-color: #242424;
        border-radius: 100px;
        &_list {
          background: #4d4d4d;
          width: 90%;
          border-radius: 20px;
          max-height: 300px;
          overflow: scroll;

          // padding: 10px;
          // top: 0;
          // left: 0;
          left: 50%;
          transform: translateX(-50%);
          &_item {
            // padding: 10px;
            // padding-left: 15px;
            padding: 5px 10px;
            border-radius: 10px;
            font-size: 16px;
            &:hover {
              color: unset;
              background: #242424;
            }
          }
        }
      }
    }
  }
}

@function vw($px-vw, $base-vw: 1920px) {
  @return ($px-vw * 100vw) / $base-vw;
}
@function vv($px-vw, $base-vw: 676px) {
  @return ($px-vw * 100vw) / $base-vw;
}

.DFeatures,
.MFeatures {
  // padding: 2em;
  // padding: vw(299px);
  width: 76vw;

  font-family: Poppins;
  padding-top: vw(80px);

  .FHeading {
    font-size: vw(36px);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e7416a;
  }
  .FNormalText {
    // font-family: Poppins;
    font-size: vw(18px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: 0.99px;
    text-align: center;
    color: #e6e6e6;
  }
  .FPoint {
    // justify-content: space-around;
    width: 100%;
    margin: vw(150px) 0;
    flex-wrap: wrap;

    &_point {
      margin-bottom: vw(10px);
      &_key {
        background-color: #e7416a;
        // font-family: Poppins;
        font-size: vw(22px);
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.99px;
        text-align: center;
        color: #e6e6e6;
        width: vw(35px);
        border-radius: 50%;
        margin-right: vw(10px);
        height: vw(35px);
      }
      &_value {
        // font-family: Poppins;
        font-size: vw(22px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.99px;
        text-align: left;
        color: #e7416a;
      }
    }
  }
  .IPoints {
    margin: vw(30px) 0;

    &_point {
      margin: vw(10px) 0;
      &_icon {
        width: vw(35px);
        height: vw(35px);
        margin: 0 vw(20px) 0 0;
        // padding: 11.6px 10.9px 11.6px 13.7px;
        background-color: #4d4d4d;
        border-radius: 50%;
      }
      &_text {
        font-size: vw(22px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #e6e6e6;
      }
    }
  }
  .FPoint2 {
    margin: vw(85px) 0;
    .FPoint_point {
      &_key {
        width: vw(50px);
        height: vw(50px);
      }
      &_value {
        color: #e6e6e6;
      }
    }
  }
  .FVideo {
  video{
    // width: 100%;
    height: 100%;

  }
    background: linear-gradient(45deg, lightblue, lightpink);
    width: vw(1100px);
    height: vw(618px);
    border-radius: 10px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.6);
    background-color: #fff;
  }
  .FColumn {
    // flex-wrap: wrap;
    .FHeading {
      text-align: left;
    }
    &_img {
      // width: 60%;
      width: vw(821px);
      img {
        width: 100%;
      }
    }
    &_text {
      display: flex;
      flex-flow: column;
      // align-items: center;
      justify-content: center;
    }
  }

  .C4 {
    margin-left: vw(80px);
  }
  .C5 {
    margin-bottom: vw(238px);
  }
  .C2 {
    margin-top: vw(200px);
  }
  .C3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .C6 {
    position: relative;
  }
  .BigGradient {
    left: 34%; //ask
    position: absolute;
    top: -60%;
    width: vw(1175px);
    height: vw(1258px);
    opacity: 0.13;
    -webkit-filter: blur(50px);
    filter: blur(50px);
    background-image: radial-gradient(
      circle at 50% 50%,
      rgba(234, 31, 114, 0.8),
      rgba(216, 102, 102, 0)
    );
  }
  .C7 {
    left: -20%;
  }
}

.MFeatures {
  width: 100vw !important;
  padding-top: unset;
  padding: vv(40px);
  box-sizing: border-box;

  .FHeading {
    font-size: vv(36px);
    text-align: center !important;
  }
  .FNormalText {
    font-size: vv(18px);
    margin-top: vv(30px);
  }
  .FPoint {
    margin: vv(92px) 0;
    &_point {
      margin-bottom: vv(34px);
      &_value {
        font-size: vv(22px);
      }
    }
  }
  .IPoints {
    margin: vv(20px) 0;

    &_point {
      margin: vv(15px) 0;
      &_icon {
        width: vv(35px);
        height: vv(35px);
        margin: 0 vv(20px) 0 0;
        img {
          width: vv(19px);
          height: vv(19px);
        }
      }
      &_text {
        font-size: vv(22px);
      }
    }
  }
  .FPoint2 {
    margin: vv(85px) 0;
    flex-direction: column;
    .FPoint_point {
      &_key {
        width: vv(50px);
        height: vv(50px);
        margin-right: vv(20px);
        // img {
        img {
          width: vv(19px);
          height: vv(19px);
          // }
        }
      }
    }
  }
  .FColumn {
    .FHeading {
      margin-bottom: vv(30px);
    }
    flex-direction: column;
    &_img {
      width: 100%;
    }
  }

  .C4 {
    margin-left: vv(40px);
  }
  .C2 {
    margin-top: vv(50px);
  }
  .BigGradient {
    width: vv(676px);
    height: vv(1288px);

    left: 6%;
    position: absolute;
    top: -81%;
  }
  .C7 {
    left: -30%;
  }
  .FVideo {
    // width: 100% !important;
    width: vv(596px) !important;
    height: vv(335px) !important;
  }
  .C5 {
    margin-bottom: vv(0px);
  }
}

.DFeatures{
  .FVideo{
  position: relative;
.btn-muted-features{
position: absolute;
z-index: 2;
top: 20px;
left: 20px;
display: flex;

img{
  margin: 0 0 0 7px;
}


}
}
}

.MFeatures{
  .FVideo{
    position: relative;
  .btn-muted-features{
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 20px;
  display: flex;
  
  img{
    margin: 0 0 0 7px;
  
  }
  
  
  }
  }  
  .btn-muted-features{
    position: absolute;
    z-index: 2;
    top: 20px;
    left: 20px;
    display: flex;
    
    img{
      margin: 0 0 0 7px;
    }
    
    
    }
  .downarrow{
    img{
      margin: auto;
      width: 8%;
    }
  }
}

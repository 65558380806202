@mixin flex($flow, $jcontent, $aitems) {
  display: flex;
  flex-flow: $flow;
  justify-content: $jcontent;
  align-items: $aitems;
}
@mixin wh($w, $h) {
  width: $w;
  height: $h;
}

@function vw($px-vw, $base-vw: 1920px) {
  @return ($px-vw * 100vw) / $base-vw;
}
@function vv($px-vw, $base-vw: 375px) {
  @return ($px-vw * 100vw) / $base-vw;
}
@mixin landing {
  background: #131313;

  // @include wh(100%, calc(100vh - 90px));
  @include wh(100%, calc(100vh));

  font-family: Poppins;
  position: relative;
  box-sizing: border-box;
}

@mixin bigContent() {
  @include flex(column nowrap, space-between, flex-start);
}

@mixin heading($size, $height, $align, $weight: 600, $color: #e7416a) {
  font-size: $size; //
  font-family: Poppins;
  font-weight: $weight;
  line-height: $height; //
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: $align; //
  color: $color;
}

@mixin ltp($left, $top, $position: relative) {
  left: $left;
  top: $top;
  position: $position;
}

@mixin input($base, $size: 16px) {
  background: #e6e6e6;
  @include flex(row nowrap, space-between, center);
  padding: vw(5px, $base);
  border-radius: vw(5px, $base);
  input {
    @include wh(100%, 100%);
    border-radius: vw(5px, $base);
    outline: none;
    border: none;
    background: #e6e6e6;

    @include heading(vw($size, $base), 1.56, left, 500, #333);
    padding: 0 vw(13px, $base);
    &::selection {
      background-color: #e7416a;
      color: white;
    }
  }
  button {
    @include wh(vw(287px, $base), 100%);
    // margin: 0 0 0 118px;
    cursor: pointer;
    padding: vw(10px, $base) vw(27px, $base);
    border-radius: vw(5px, $base);
    border: none;

    @include heading(vw($size, $base), 1.56, center, 400, #e6e6e6);
    background-image: linear-gradient(77deg, #e72870 -4%, #e76161);
  }
}
@mixin info($size: 14px, $margin: 40px, $margini: 15px, $base: 1920px) {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  transition-duration: 0.5s;
  @include flex(row nowrap, flex-start, center);
  //   width: 100%;
  position: relative;
  z-index: 2;

  &_col {
    margin-right: vw($margin, $base);
    &_row {
      @include flex(row nowrap, flex-start, center);
      img {
        @include wh(vw(22px, $base), vw(20px, $base));

        margin-right: vw($margini, $base);
      }
      label {
        @include heading(vw($size, $base), 1.5, left, normal, #a9a9a9);
      }
      margin-bottom: vw(20px, $base);
    }
  }
}

.Desktop {
  .LandingPage {
    @include landing();
    padding: vw(80px) vw(120px) vw(40px) vw(120px);
    @include flex(row nowrap, space-between, flex-start);
    &_bigContent {
      @include bigContent();
      @include wh(vw(1410px), 100%);
      &_info {
        width: vw(900px);

        @include info();
        //   background: red;
      }
      &_logo {
        @include wh(vw(190px), vw(36px));
        // background: red;
      }
    }

    &_content {
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
      z-index: 3;
      @include flex(column nowrap, flex-start, flex-start);

      @include ltp(0, vw(30px));
      &_heading {
        @include heading(vw(82px), 1.22, left);

        position: relative;
        img {
          @include ltp(vw(80px), vw(80px));
          @include wh(vw(180px), vw(180px));
        }
      }
      &_subheading {
        margin-top: vw(7px);
        @include heading(vw(28px), 1.79, left, 300, #e5e5e5);
      }
      &_input {
        // background: none !important;
        @include wh(vw(522px), vw(40px));
        margin: vw(30px) 0;

        @include input(1920px);
        font-weight: 500;
        .LOADING_IMG {
          // width: vw(55px);
          // height: vw(35px);
          // position: relative;
          // transform: scale(0.4);
          // @include ltp(vw(10px), vw(-43px));
          img {
            @include ltp(vw(10px), vw(-10px));
            width: vw(40px);
            height: vw(40px);
          }
        }
      }
    }

    &_gif {
      position: absolute;

      @include wh(100%, 100%);
      bottom: 0;
      @include flex(row nowrap, center, flex-end);
      .videoWrapper {
        @include wh(100%, 100%);
        @include ltp(vw(350px), vw(50px));
      }
      video {
        @include wh(100vw, 100%);
      }
    }
  }
}

@mixin controls() {
  .controls {
    @include ltp(0, 0, absolute);
    // @include wh(100%,100%);
    @include wh(100%, 0%);
    display: none;
    z-index: 99;
  }
  .controls_close {
    position: absolute;
    right: 0;
    @include wh(vw(40px), vw(40px));
    margin: vw(50px);
    cursor: pointer;
    z-index: 99;
  }
  .controls_play {
    @include ltp(50%, 50%, absolute);
    transform: translate(-50%, -50%);
    transition-duration: 0.75s;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    z-index: 99;
    cursor: pointer;
    opacity: 0;
  }
  .controls_play_animation {
    transform: translate(-50%, -50%) scale(2);
  }
}
.Desktop {
  .SENDED {
    color: #e72870 !important;
    background: unset !important;
    width: vv(70px) !important;
    font-weight: 600 !important;
    img {
      width: vw(13.6px);
      height: vw(9.4px);
    }
  }
  .SENDED_LABEL {
    color: #e7416a;
    font-size: vv(3.5px);
  }
  .videoWrapper_active {
    transition-duration: 0.75s;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    @include ltp(0 !important, 0 !important, fixed !important);
    @include wh(100%, 100% !important);
    @include flex(row nowrap, center, center);

    video {
      @include wh(100vw, 100%);
      transform: scale(1);
    }
    .bigVid {
      // transform: scale(1.09);
      z-index: 9;
    }
  }
  .ClickableArea {
    // background: rgba(55, 0, 255, 0.322);
    position: absolute;
    @include wh(35%, 88%);
    bottom: 0;
    left: 32%;
    border-radius: 50% 50% 0 0;
    cursor: pointer;
  }

  .playButton {
    @include ltp(45.5%, 49.5%, absolute);
    @include wh(vw(147px), vw(82px));
  }
  @include controls();
  .bigVid {
    // width: 100vw !important;
    // height: 100%;
    // width: 100vw;
    // width: unset !important;
    z-index: 9;
    display: none;
  }
  ::selection {
    background-color: transparent;
  }
}

body,
html {
  margin: 0;
  background-color: #131313;
  padding: 0;
  #root {
    overflow: hidden;
  }
}

.puff-out-center {
  -webkit-animation: puff-out-center 0.75s cubic-bezier(0.165, 0.84, 0.44, 1)
    both;
  animation: puff-out-center 0.75s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

@mixin sbo($t, $f, $o) {
  -webkit-transform: scale($t);
  transform: scale($t);
  -webkit-filter: blur($f);
  filter: blur($f);
  opacity: $o;
}
@-webkit-keyframes puff-out-center {
  0% {
    @include sbo(1, 0px, 1);
  }
  100% {
    @include sbo(2, 4px, 0);
  }
}
@keyframes puff-out-center {
  0% {
    @include sbo(1, 0px, 1);
  }
  100% {
    @include sbo(2, 4px, 0);
  }
}

.Mobile {

  .DIFFCOL {
    color: #e5e5e5 !important;
    font-size: vv(11px) !important;
    left: 20%;
  }
  .LandingPage {
    @include landing();
    // for testing landing page scroll issue
    // overflow: scroll;  

    &_bigContent {
      // height: 100%;
      // overflow: scroll;
      padding: vv(20px) vv(16px);
      width: 90%;
      @include bigContent();
      height: unset;
      justify-content: flex-start;
      // overflow: scroll;
      &_logo {
        @include wh(vv(84px), vv(16px));
      }
      &_info {
        width: 100%;
        margin: vv(31px) 0;
        @include info(10px, 10px, 5px, 375px);
        justify-content: space-between;

        &_col {
          &_row {
            img {
              // @include wh(vv(15px), vv(10px));
              width: vv(15px) !important;
              height: vv(15px) !important;
              margin-right: vv(10px) !important;
            }
          }
        }
      }
    }
    &_content {
      width: 100%;
      &_heading {
        @include heading(vv(32px), 1.25, center);
        margin-top: vv(28px);
      }
      &_subheading {
        @include heading(vv(14px), 1.5, center, 400, #e6e6e6);
        margin-top: vv(10px);
      }
      &_input {
        @include input(375px, 11px);
        height: vv(35px);
        margin-top: vv(33px);
        // background: none;
        a{
          margin: auto;
        }
      }
    }

    &_gif {
      @include wh(100%, vv(206px));
      .videoWrapper {
        margin-top: vv(30px);
        @include wh(100vw, 100%);
        position: relative;
        left: vv(-20px);

        .bigVid {
          display: none;
          z-index: 99;
        }
        .vid,
        .bigVid {
          @include wh(100%, 100%);
        }
        .playButton {
          @include ltp(50%, 50%, absolute);
          transform: translate(-50%, -50%);
          @include wh(vv(61px), vv(34px));
        }
        .ClickableArea {
          // background: rgba(55, 0, 255, 0.322);
          position: absolute;
          @include wh(35%, 88%);
          bottom: 0;
          left: 32%;
          border-radius: 50% 50% 0 0;
          cursor: pointer;
        }
      }
    }
  }
  @include controls();
  .SENDED {
    color: #e72870 !important;
    background: unset !important;
    width: vv(350px) !important;
    font-weight: 600 !important;
    img.loading {
      width: vv(40px);
      height: vv(40px);
      @include ltp(vv(15px), vv(-13px));
    }
  }
  .SENDED_LABEL {
    color: #e7416a;
    font-size: vv(14px);
    // margin-top: vv(-15px);
    top: vv(10px);
    position: relative;
  }
}

.Loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 4;
}

.DIFFCOL {
  color: #e5e5e5 !important;
}

.flex {
  display: flex;
}
.column {
  flex-flow: column;
}
.jcenter {
  justify-content: center;
}
.jaround {
  justify-content: space-around;
}
.jbetween {
  justify-content: space-between;
}
.acenter {
  align-items: center;
}

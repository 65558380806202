@function vw($px-vw, $base-vw: 1920px) {
  @return ($px-vw * 100vw) / $base-vw;
}
@function vv($px-vw, $base-vw: 676px) {
  @return ($px-vw * 100vw) / $base-vw;
}

.DToggle {
  width: vw(220px);
  height: vw(50px);

  // margin: 0 2.5px 78px 0;
  // padding: 5px 5px 5px 21px;
  padding: vw(5px);
  box-sizing: border-box;
  border-radius: vw(25px);
  background-color: #242424;
  cursor: pointer;
  &_button {
    width: 100%;
    font-size: vw(16px);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.38;
    letter-spacing: 0.64px;
    text-align: left;
    color: #e5e5e5;
  }
}
.DToggle_active {
  width: vw(110px);
  height: vw(40px);
  // margin: 0 0 0 vw(16px);
  padding: 0 vw(28px);
  border-radius: vw(25px);
  background-color: #e7416a;
}

.PricingCard {
  border: solid 1px #5a2634;
  // background-color: #1c1c1c;

  width: vw(480px);
  height: vw(652px);
  box-sizing: border-box;
  padding: vw(36px) vw(40px) vw(45px);
  border-radius: vw(30px);

  background-color: #1c1c1c;
  &_type {
    font-size: vw(28px);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #e7416a;
  }
  &_popular_tag {
    padding: vw(5px) vw(21px) vw(5px) vw(26px);
    border-radius: vw(30px);
    background-color: #5a2634;
    font-size: vw(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.71;
    letter-spacing: normal;
    text-align: center;
  }
  &_about {
    font-size: vw(16px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.38;
    letter-spacing: normal;
    text-align: left;
    color: #e6e6e6;
    margin: vw(20px) 0;
  }
  &_price {
    align-items: flex-end;
    &_value {
      font-size: vw(48px);
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.79;
      letter-spacing: normal;
      text-align: left;
      color: #e7416a;
    }
    &_timetype {
      font-size: vw(20px);
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      // line-height: 1.9;
      letter-spacing: normal;
      text-align: left;
      color: #4d4d4d;
    }
  }
  &_annual {
    font-size: vw(15.5px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.71;
    letter-spacing: normal;
    text-align: left;
    color: #a9a9a9;
  }

  &_try {
    cursor: pointer;
    width: vw(404px);
    height: vw(50px);

    margin: vw(51px) 0 vw(10px);
    // padding: vw(13px 153.4px 12px 154.6px;
    border-radius: vw(25px);
    background-color: #e7416a;
    font-size: vw(18px);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    // line-height: 2.11;
    letter-spacing: normal;
    text-align: center;
    color: #e5e5e5;
  }
  &_card {
    font-size: vw(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    // line-height: 2.71;
    letter-spacing: normal;
    text-align: center;
    color: #a9a9a9;
  }

  &_benefit {
    margin-top: vw(60px);
    &_point {
      font-size: vw(16px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.38;
      letter-spacing: normal;
      text-align: left;
      color: #e6e6e6;
      display: flex;
      justify-content: start;
      align-items: center;
      flex-wrap: nowrap;
      img {
        margin: vw(2px) vw(20px) vw(1px) 0;
        object-fit: contain;
        width: vw(20px);
        height: vw(20px);
      }
    }
  }
  &_custom {
    &_big {
      font-size: vw(28px);
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: 1.4px;
      text-align: left;
      color: #e7416a;
    }
    &_small {
      font-size: vw(16px);
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.38;
      letter-spacing: 0.48px;
      text-align: left;
      color: #4d4d4d;
    }
  }
}

.PricingCard_border {
  border: solid vw(5px) #e7416a;
  margin: 0 vw(38px);
  width: vw(484px);
  height: vw(668px);
}

.DPricing,
.MPricing {
  &_arrow{
    
  }
  color: #e6e6e6;
  font-family: "Poppins";
  &_list {
    width: 100%;
    margin-bottom: vw(70px);
  }
  &_toggle {
    margin-bottom: vw(70px);
  }
  &_heading {
    font-size: vw(28px);
    margin: vw(30px) 0 vw(60px) 0;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e6e6e6;
  }
}

.MPricing {
  
  .Faq .faq-row p {
    
    padding: 8px 0;
}
  .Faq .faq-row {
    width: 88%;
    margin: 7.5px auto;
}
  .DPricing_list {
    width: 100%;
    margin-bottom: vv(25px);
    overflow-x: scroll;
    padding: 0 vv(80px);
    box-sizing: border-box;
    padding-bottom: vv(20px);
  }
  .DPricing_toggle {
    margin-bottom: vv(42px);
  }
  .DPricing_heading {
    font-size: vv(36px);
    margin: vv(30px) 0 vv(60px) 0;
  }
  .DToggle {
    width: vv(298px);
    height: vv(66px);

    padding: vv(5px);
    border-radius: vv(33px);

    &_button {
      font-size: vv(23px);
    }
  }
  .DToggle_active {
    width: vv(144px);
    height: vv(56px);
    // margin: 0 0 0 vv(16px);
    padding: 0 vv(26px);
    border-radius: vv(33px);
  }

  .PricingCard {
    width: vv(480px);
    height: vv(652px);
    padding: vv(36px) vv(40px) vv(45px);
    border-radius: vv(30px);

    &_type {
      font-size: vv(28px);
    }
    &_popular_tag {
      padding: vv(5px) vv(21px) vv(5px) vv(26px);
      border-radius: vv(30px);

      font-size: vv(14px);
    }
    &_about {
      font-size: vv(16px);

      margin: vv(20px) 0;
    }
    &_price {
      &_value {
        font-size: vv(48px);
      }
      &_timetype {
        font-size: vv(20px);
      }
    }
    &_annual {
      font-size: vv(19px);
    }

    &_try {
      width: vv(404px);
      height: vv(50px);
      margin: vv(51px) 0 vv(10px);
      border-radius: vv(25px);
      font-size: vv(18px);
    }
    &_card {
      font-size: vv(14px);
    }

    &_benefit {
      margin-top: vv(60px);
      &_point {
        font-size: vv(16px);
        img {
          margin: vv(2px) vv(20px) vv(1px) 0;

          width: vv(20px);
          height: vv(20px);
        }
      }
    }
    &_custom {
      &_big {
        font-size: vv(28px);
      }
      &_small {
        font-size: vv(16px);
      }
    }
  }

  .PricingCard_border {
    border: solid vv(5px) #e7416a;
    margin: 0 vv(38px);
    width: vv(484px);
    height: vv(668px);
  }
}


.save50p{
  position: relative;

  label{
    position: absolute;
    right: -64px;
    top: 4px;
  }
}

.PricingCard_annual.acenter.SwipePlans{
  text-align: center;
  // margin-bottom: 20px;
  font-size: vv(18px);
}


._buynow{
  width: vv(200px);
  height: vv(100px);
  &_emailNe{
    width: 100%;
    height: vv(17px);
    margin: vv(10px) 0 vv(10px);
    padding: 0 10px;
    border-radius: vv(25px);
    font-size: vv(18px);
    background: #242424;

    color: #e6e6e6;
    font-size: 16px;
    border: none;
    border-radius: 100px;
    margin-right: 1em;
    outline: none;
  }

  #isDealEnded{
    font-size: vv(8px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;

  }

  .isDealEndedFalse{
    display: none !important;
  }
  .isDealEndedTrue{
    display: block;
  }

  .Popup_footer.flex{
    margin: 20px 0 0 0; 
  }
}

._buynow.MPricing{
  width: vv(500px);
  height: vv(200px);

  .Popup_header {
    justify-content: space-between;
    margin-bottom: vv(15px);
}
  ._buynow_emailNe{
    height: vv(37px);
    margin: vv(5px) 0 vv(5px);


  }

  #isDealEnded{
    
      font-size: vv(25px);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
    

  }
  
}

._buynow._paymentPopup {
    position: absolute;
    left: 34%;
    top: 12%;
}

._buynow.MPaymentSuccess {
  position: absolute;
  left: 6%;
  top: 10%;
}
@function vw($px-vw, $base-vw: 1920px) {
    @return ($px-vw * 100vw) / $base-vw;
  }
  @function vv($px-vw, $base-vw: 676px) {
    @return ($px-vw * 100vw) / $base-vw;
  }
  

.Faq{
    margin: 0 auto 60px auto;
    .faq-row{
        
        width: 70%;
        margin: 7.5px auto;
        p{
            font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #e6e6e6;
    padding: 5px 0;
        }
    }
    /* Style the button that is used to open and close the collapsible content */
.collapsible {
    // background-color: #eee;
    cursor: pointer;

    width: 100%;
    
    text-align: left;

    background-color: #1c1c1c;
    // font-size: vw(28px);
    // margin: vw(30px) 0 vw(60px) 0;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    padding: 10px;
    border-radius: 5px;
  }

  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .collapsible img {
    // background-color: #ccc;
    transition:  0.2s ease-out;
  }
  .active.collapsible img {
    transform: rotate(180deg)
  }




  
  /* Style the collapsible content. Note: hidden by default */
  .content {
    padding: 0 18px;
//   background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  }
  


}